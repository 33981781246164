import { createSlice } from '@reduxjs/toolkit';

const initialState: ChatBotPageState = {
    chatbotLoading: {
        status: false,
    },
    chatbotTableData: [],
    chatbotNextPageToken: null,
    modelActionstatus: false
};
interface ChatBotPageState {
    chatbotLoading: {
        status: boolean;
    };
    modelActionstatus: boolean;
    chatbotTableData: any;
    chatbotNextPageToken: string | null;
}


export const ChatbotPageSlice = createSlice({
    name: 'ChatbotPage',
    initialState,
    reducers: {
        setChatbotTableData: (state, action) => {
            state.chatbotTableData = action.payload;
            if (state.chatbotTableData.length !== 0) {
                let uniqueObjects = state.chatbotTableData.filter(
                    (obj: any, index: any, self: any) => index === self.findIndex((o: any) => o.id === obj.id),
                );
                state.chatbotTableData = uniqueObjects;
            }
        },
        setAddChatbotData: (state, action) => {
            const chatbotId = action.payload.client_id
            state.chatbotTableData = state.chatbotTableData.filter(
                (chatbot: any) => chatbot.client_id !== chatbotId,
            );
            state.chatbotTableData.push(action.payload);
        },
        setEditChatbotData: (state, action) => {
            const chatbotId = action.payload.id
            state.chatbotTableData = state.chatbotTableData.filter(
                (chatbot: any) => chatbot.id !== chatbotId,
            );
            state.chatbotTableData.push(action.payload);
        },
        setDeleteChatbotData: (state, action) => {
            const chatbotIDsToDelete = action.payload || [];
            chatbotIDsToDelete.forEach((chatbotId: any) => {
                state.chatbotTableData = state.chatbotTableData.filter(
                    (chatbot: any) => chatbot.id !== chatbotId,
                );
            });
        },
        setChatbotNextPageToken: (state, action) => {
            state.chatbotNextPageToken = action.payload;
        },
        setChatbotLoading: (state, action) => {
            state.chatbotLoading.status = action.payload;
        },
    },
});

export const {
    setChatbotTableData,
    setAddChatbotData,
    setEditChatbotData,
    setDeleteChatbotData,
    setChatbotNextPageToken,
    setChatbotLoading,
} = ChatbotPageSlice.actions;

export const chatbotLoading = (state: any) => state.chatbotPage.chatbotLoading;
export const chatbotTableData = (state: any) => state.chatbotPage.chatbotTableData;
export const chatbotNextPageToken = (state: any) => state.chatbotPage.chatbotNextPageToken;
export default ChatbotPageSlice.reducer;
