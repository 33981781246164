import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";

import {
  setSessionId,
  setChatHistoryData,
  setUserSessions,
} from "../Features/HomeSlice";

const API_URL = (window as any).APP_CONFIG.REACT_APP_API_URL;
const LLM_API_URL = (window as any).APP_CONFIG.REACT_APP_API_URL;

export const getCognitoToken = async () => {
  const userToken = await Auth.currentSession();
  const id = userToken.getIdToken();
  const jwt = id.getJwtToken();
  return jwt;
};

// Define an asynchronous thunk for starting a chat session
export const handleStartChat = createAsyncThunk(
  "HomeThunk/handleStartChat",
  async (data: any, { dispatch }: { dispatch: any, }) => {
    // Set headers for the HTTP request with JSON content type and Cognito token
    const headers = {
      "Content-Type": "application/json",
      Authorization: await getCognitoToken(),
    };

    try {
      // Make a POST request to the chathistory endpoint with provided data
      let response: any = await fetch(API_URL + "/chathistory", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      });
      // Parse the JSON response from the server
      response = await response.json();
      // Dispatch an action to set the session ID based on the server response
      dispatch(setSessionId(response.sessionid));
    } catch (error) {
      // Log an error message if there is an issue with the API request
      console.error("API error:", error);
    }
  }
);

// Define an asynchronous thunk for retrieving session history
export const handleGetSessionHistory = createAsyncThunk(
  "HomeThunk/handleGetSessionHistory",
  async (data: any, { dispatch }: { dispatch: any, }) => {
    // Set headers for the HTTP request with JSON content type and Cognito token
    const headers = {
      "Content-Type": "application/json",
      Authorization: await getCognitoToken(),
    };

    try {
      // Make a GET request to the chathistory endpoint with the provided session ID
      let response = await fetch(
        `${API_URL}/chathistory?sessionid=${encodeURIComponent(data)}`,
        {
          method: "GET",
          headers: headers,
        }
      );
      // Parse the JSON response from the server
      response = await response.json();
      // Dispatch actions to set the chat history data and session ID based on the server response
      dispatch(setChatHistoryData(response));
      dispatch(setSessionId(data));
    } catch (error) {
      // Log an error message if there is an issue with the API request
      console.error("API error:", error);
    }
  }
);

// Define an asynchronous thunk for retrieving user sessions
export const handleGetUserSessions = createAsyncThunk(
  "HomeThunk/handleGetUserSessions",
  async (data: any, { dispatch }: { dispatch: any, }) => {
    // Set headers for the HTTP request with JSON content type and Cognito token
    const headers = {
      "Content-Type": "application/json",
      Authorization: await getCognitoToken(),
    };

    try {
      let response = await fetch(`${API_URL}/chathistory?userid=${data}`, {
        method: "GET",
        headers: headers,
      });
      // Parse the JSON response from the server
      response = await response.json();
      // Dispatch an action to set the User Sessions based on the server response
      dispatch(setUserSessions(response));
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

// Define an asynchronous thunk for handling chat interactions
export const handleChat = createAsyncThunk(
  "HomeThunk/handleChat",
  async (data: string, { dispatch, getState }: { dispatch: any, getState: () => any }) => {
    // Prepare payload with question, session ID, and connection ID
    const payload = {
      question: data,
      sessionid: getState().home?.sessionid,
      connectionId: getState().home?.connectionId
    };
    // Set headers for the HTTP request with JSON content type and Cognito token
    const headers = {
      "Content-Type": "application/json",
      Authorization: await getCognitoToken(),
    };

    try {
      let response: any = await fetch(LLM_API_URL + "/chat", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
      response = await response.json();
      // Dispatch an action to get user sessions based on the authenticated username
      dispatch(handleGetUserSessions(getState().auth?.username?.username));
      return response.result;
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

// Define an asynchronous thunk for handling image interactions
export const handleImage = createAsyncThunk(
  "HomeThunk/handleImage",
  async (data: string, { dispatch, getState }: { dispatch: any, getState: () => any }) => {
    // Prepare payload with image, session ID
    const payload = {
      image: data,
      sessionid: getState().home?.sessionid,
    };
    // Set headers for the HTTP request with JSON content type and Cognito token
    const headers = {
      "Content-Type": "application/json",
      Authorization: await getCognitoToken(),
    };
    try {
      let response: any = await fetch(LLM_API_URL + "/image", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
      response = await response.json();
      // Dispatch an action to get user sessions based on the authenticated username
      dispatch(handleGetUserSessions(getState().auth?.username?.username));
      return response.result;
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

// Define an asynchronous thunk for handling Semantic Search interactions
export const handleSemanticSearch = createAsyncThunk(
  "HomeThunk/handleSemanticSearch",
  async (data: string, { dispatch, getState }: { dispatch: any, getState: () => any }) => {
    // Prepare payload with search, session ID, and connection ID
    const payload = {
      promptId: 'default_id_1',
      search: data,
      sessionid: getState().home?.sessionid,
      connectionId: getState().home?.connectionId
    };
    try {
      // Set headers for the HTTP request with JSON content type and Cognito token
      const headers = {
        "Content-Type": "application/json",
        Authorization: await getCognitoToken(),
      };
      let response: any = await fetch(LLM_API_URL + "/semantic_search", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
      response = await response.json();
      // Dispatch an action to get user sessions based on the authenticated username
      dispatch(handleGetUserSessions(getState().auth?.username?.username));
      return response.result;
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

// Define an asynchronous thunk for handling Query interactions
export const handleQuery = createAsyncThunk(
  "HomeThunk/handleQuery",
  async (data: string, { dispatch, getState }: { dispatch: any, getState: () => any }) => {
    // Prepare payload with query, session ID, and connection ID
    const payload = {
      query: data,
      sessionid: getState().home?.sessionid,
      connectionId: getState().home?.connectionId
    };
    // Set headers for the HTTP request with JSON content type and Cognito token
    const headers = {
      "Content-Type": "application/json",
      Authorization: await getCognitoToken(),
    };

    try {
      let response: any = await fetch(LLM_API_URL + "/query", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
      response = await response.json();
      // Dispatch an action to get user sessions based on the authenticated username
      dispatch(handleGetUserSessions(getState().auth?.username?.username));
      return response.result;
    } catch (error) {
      console.error("API error:", error);
    }
  }
);