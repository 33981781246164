import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    FormField,
    Flashbar,
    Link,
    Input,
    RadioGroup,
} from '@cloudscape-design/components';

import { WizdomTitleLogo, EyeHidden, EyeDisplay } from '../../assets';
import { validatePasswordRequirement } from './auth.config';
import {
    selectEmail,
    setActiveTabId,
    setResetPassword,
    setResetPasswordSuccess,
} from '../../Redux/Features/AuthSlice';

export default function ResetPassword() {
    const [codeSent, setCodeSent] = useState(false);
    const [email, setEmail] = useState(useSelector(selectEmail));
    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [errors, setErrors] = useState<any>({});
    const [resendCode, setResendCode] = useState(false);
    const [codeSendErrorFlashbar, setCodeSendErrorFlashbar] = useState<any>([]);
    const [pwChar, setPwChar] = useState('');
    const [pwCap, setPwCap] = useState('');
    const [pwNum, setPwNum] = useState('');
    const [pwSpecial, setPwSpecial] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const dispatch = useDispatch();
    const [items, setItems] = useState([
        {
            type: 'success',
            content: 'Successfully resent code!',
            dismissible: true,
            onDismiss: () => {
                setItems([]);
                setResendCode(false);
            },
        },
    ]);
    const [codeSentFlashBarItems, setCodeSentFlashBarItems] = useState([
        {
            type: 'info',
            content: (
                <>
                    <strong>We emailed you.</strong>
                    <p>Your code is on the way - it may take a minute to arrive.</p>
                </>
            ),
            dismissible: true,
            onDismiss: () => {
                setCodeSentFlashBarItems([]);
            },
        },
    ]);

    useEffect(() => {
        if (pwChar && pwCap && pwNum && code && pwSpecial) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [pwChar, pwCap, pwNum, pwSpecial, code]);

    async function sendResetPasswordCode() {
        if (!email) {
            setErrors({
                email: 'Email field is required.',
            });
            return;
        }

        try {
            await Auth.forgotPassword(email);
            setCodeSent(true);
        } catch (err: any) {
            setCodeSendErrorFlashbar([
                {
                    header: err.message,
                    type: 'error',
                    dismissible: true,
                    dismissLabel: 'Dismiss message',
                    onDismiss: () => setCodeSendErrorFlashbar([]),
                    id: 'message_1',
                },
            ]);
        }
    }
    // Reset Password 
    async function resetPassword() {
        try {
            const user = await Auth.forgotPasswordSubmit(email, code, newPassword);
            dispatch(setActiveTabId('sign-in'));
            dispatch(setResetPassword(false));
            dispatch(setResetPasswordSuccess(true));
            console.log('user', user);
        } catch (err: any) {
            setErrors({
                code: 'Invalid verification code provided, please try again.',
            });
        }
    }

    // Resend Password Code
    async function resendPasswordCode() {
        setCodeSentFlashBarItems([]);
        try {
            await Auth.forgotPassword(email);
            setResendCode(true);
        } catch (err: any) {
            setCodeSendErrorFlashbar([
                {
                    header: err.message,
                    type: 'error',
                    dismissible: true,
                    dismissLabel: 'Dismiss message',
                    onDismiss: () => setCodeSendErrorFlashbar([]),
                    id: 'message_1',
                },
            ]);
            setItems([]);
        }
    }

    return (
        <Box>
            {/* Logo Section */}
            <Box textAlign='center'>
                <img
                    data-testid='WizdomTitleLogo'
                    src={WizdomTitleLogo}
                    alt=''
                    style={{ height: '100%', width: '350px', maxHeight: '150px' }}
                />
            </Box>
            {resendCode && <Flashbar items={items as any[]} />}
            {codeSent && <Flashbar items={codeSentFlashBarItems as any[]} />}
            {codeSendErrorFlashbar.length !== 0 && <Flashbar items={codeSendErrorFlashbar} />}
            <Box margin={{ bottom: 's' }} />
            <Box variant='h3'> Reset password</Box>
            {codeSent && (
                <div onClick={resendPasswordCode} >
                    <Link>Resend code</Link>
                </div>
            )}
            <Box margin={{ bottom: 'l' }} />
            {codeSent ? (
                <>
                    {/* Form Field for Code */}
                    <FormField label='Code' errorText={errors['code']}>
                        <Input
                            value={code}
                            onChange={(event) => {
                                const err = { ...errors };
                                if (err['code']) delete err['code'];
                                setErrors(err);
                                setCode(event.detail.value);
                            }}
                            placeholder='Enter code'
                        />
                    </FormField>
                    <Box margin={{ bottom: 'm' }} />
                    {/* Form Field for New Password */}
                    <FormField label='New Password' errorText={errors['newPassword']}>
                        <Input
                            value={newPassword}
                            onChange={(event) => {
                                const err = { ...errors };
                                if (err['newPassword']) delete err['newPassword'];
                                setErrors(err);
                                setNewPassword(event.detail.value);
                                validatePasswordRequirement(
                                    event.detail.value,
                                    setPwChar,
                                    setPwNum,
                                    setPwCap,
                                    setPwSpecial
                                );
                            }}
                            placeholder='Enter new password'
                            type={showPassword ? 'text' : 'password'}
                        />
                        {showPassword ? (
                            <div
                                style={{ position: 'absolute', right: 18, bottom: 8 }}
                                onClick={() => setShowPassword(!showPassword)}
                                data-testid='NewEyeHidden'
                            >
                                <img src={EyeHidden} alt='' />
                            </div>
                        ) : (
                            <div
                                style={{ position: 'absolute', right: 20, bottom: 14 }}
                                onClick={() => setShowPassword(!showPassword)}
                                data-testid='NewEyeDisplay'
                            >
                                <img src={EyeDisplay} alt='' />
                            </div>
                        )}
                    </FormField>
                    {/* Password Info */}
                    <Box textAlign='center' padding={{ top: 's', left: 'm' }}>
                        <RadioGroup
                            onChange={({ detail }) => console.log(detail.value)}
                            value={pwChar}
                            data-testid="pwChar"
                            items={[
                                {
                                    value: 'pwChar',
                                    label: 'At least 8 characters',
                                    disabled: pwChar ? false : true,
                                },
                            ]}
                        />
                        <RadioGroup
                            onChange={({ detail }) => console.log(detail.value)}
                            value={pwCap}
                            items={[
                                {
                                    value: 'pwCap',
                                    label: 'At least 1 capital letter',
                                    disabled: pwCap ? false : true,
                                },
                            ]}
                        />
                        <RadioGroup
                            onChange={({ detail }) => console.log(detail.value)}
                            value={pwNum}
                            items={[
                                {
                                    value: 'pwNum',
                                    label: 'At least 1 number',
                                    disabled: pwNum ? false : true,
                                },
                            ]}
                        />
                        <RadioGroup
                            onChange={({ detail }) => console.log(detail.value)}
                            value={pwSpecial}
                            items={[
                                {
                                    value: 'pwSpecial',
                                    label: 'At least 1 symbol character',
                                    disabled: pwSpecial ? false : true,
                                },
                            ]}
                        />
                    </Box>
                    <div style={{ marginBottom: '45px' }} />

                    <div className='sign-in-footer'>
                        <div
                            onClick={() => dispatch(setResetPassword(false))}
                            className='forgot-password'
                        >
                            <Link>Back to sign in</Link>
                        </div>
                        <Button
                            variant='primary'
                            data-id='auth-button'
                            onClick={resetPassword}
                            disabled={buttonDisabled}
                        >
                            Reset password
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    {/* Form Field for Email */}
                    <FormField label='Email' errorText={errors['email']}>
                        <Input
                            value={email}
                            onChange={(event) => {
                                const err = { ...errors };
                                if (err['email']) delete err['email'];
                                setErrors(err);
                                setEmail(event.detail.value);
                            }}
                            placeholder='Enter your email'
                        />
                    </FormField>
                    <div style={{ marginBottom: '45px' }} />

                    <div className='sign-in-footer'>
                        <div
                            onClick={() => dispatch(setResetPassword(false))}
                            className='forgot-password'
                        >
                            <Link data-id='forgot-password-button'>Back to sign in</Link>
                        </div>
                        <Button
                            variant='primary'
                            data-id={!email ? 'auth-button-disabled' : 'auth-button'}
                            onClick={sendResetPasswordCode}
                            disabled={!email}
                        >
                            Send me a code
                        </Button>
                    </div>
                </>
            )}
        </Box>
    );
}
