import { colorBackground } from "./global-visual-context/colorBackground";
import { colorBorder } from "./global-visual-context/colorBorder";
import { colorForeGround } from "./global-visual-context/colorForeground";
import { colorText } from "./global-visual-context/colorText";
import { colorButton } from "./global-visual-context/colorButton";

export const theme = {
  tokens: {
    ...colorBackground,
    ...colorBorder,
    ...colorForeGround,
    ...colorText,
    ...colorButton
  },
  contexts: {
    header: {
      tokens: {
        colorBackgroundLayoutMain: { light: "", dark: "" },
      },
    },
    // navigation: {},
    // flashbar: {},
    // alert: {},
  },
};
