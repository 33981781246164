import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import config from "./aws-export";
import "@cloudscape-design/global-styles/index.css";
import { Provider } from "react-redux";
import { applyTheme } from "@cloudscape-design/components/theming";
import { theme } from "./theme";

//import "./index.css";
import "./styles.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import { AmplifyProvider } from "@aws-amplify/ui-react";
import AppAuth from "./components/authentication/AppAuth";
import Store from "./Redux/Store";

Amplify.configure(config);

const { reset } = applyTheme({ theme });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <Authenticator.Provider>
        <BrowserRouter>
          <AppAuth>
            <App />
          </AppAuth>
        </BrowserRouter>
      </Authenticator.Provider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
