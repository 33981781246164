import { primitives } from '../colors';

export const colorBackground = {
  //color/Background/Button/Normal
  colorBackgroundButtonNormalActive: { light: primitives.colorBackgroundB1, dark: '' },
  colorBackgroundButtonNormalDefault: { light: primitives.colorBackgroundB0, dark: '' },
  colorBackgroundButtonNormalDisabled: { light: primitives.colorBackgroundB0, dark: '' },
  colorBackgroundButtonNormalHover: { light: primitives.colorBackgroundB2, dark: '' },
  //color/Background/Button/Primary
  colorBackgroundButtonPrimaryActive: { light: primitives.colorPrimaryP1, dark: '' },
  colorBackgroundButtonPrimaryDefault: { light: primitives.colorPrimaryP3, dark: '' },
  colorBackgroundButtonPrimaryDisabled: { light: primitives.colorExtendedNeutralN400, dark: '' },
  colorBackgroundButtonPrimaryHover: { light: primitives.colorPrimaryP3, dark: '' },
  //color/Background/Cell
  colorBackgroundCellShaded: { light: '', dark: '' },
  //color/Background/Container
  colorBackgroundContainerContent: { light: '', dark: '' },
  colorBackgroundContainerHeader: { light: '', dark: '' },
  //color/Background/Control
  colorBackgroundControlChecked: { light: primitives.colorPrimaryP3, dark: '' },
  colorBackgroundControlDefault: { light: '', dark: '' },
  colorBackgroundControlDisabled: { light: '', dark: '' },
  //color/Background/DropdownItem
  colorBackgroundDropdownItemDefault: { light: '', dark: '' },
  colorBackgroundDropdownItemFilterMatch: { light: '', dark: '' },
  colorBackgroundDropdownItemHover: { light: '', dark: '' },
  //color/Background/Home
  colorBackgroundHomeHeader: { light: primitives.colorPrimaryP3, dark: '' },
  //color/Background/Input
  colorBackgroundInputDefault: { light: '', dark: '' },
  colorBackgroundInputDisabled: { light: '', dark: '' },
  //color/Background/Item
  colorBackgroundItemSelected: { light: '', dark: '' },
  //color/Background/Layout
  colorBackgroundLayoutMain: { light: primitives.colorExtendedNeutralN100, dark: '' },
  colorBackgroundLayoutToggleActive: { light: '', dark: '' },
  colorBackgroundLayoutToggleDefault: { light: '', dark: '' },
  colorBackgroundLayoutToggleHover: { light: '', dark: '' },
  colorBackgroundLayoutToggleSelectedActive: { light: '', dark: '' },
  colorBackgroundLayoutToggleSelectedDefault: { light: '', dark: '' },
  colorBackgroundLayoutToggleSelectedHover: { light: '', dark: '' },
  //color/Background/Notification
  colorBackgroundNotificationBlue: { light: primitives.colorDataVisualizationBlue, dark: '' },
  colorBackgroundNotificationGreen: { light: primitives.colorSuccessMain, dark: '' },
  colorBackgroundNotificationRed: { light: primitives.colorPrimaryP3, dark: '' },
  //color/Background/Popover
  colorBackgroundPopover: { light: '', dark: '' },
  //color/Background/Status
  colorBackgroundStatusError: { light: '', dark: '' },
  colorBackgroundStatusInfo: { light: '', dark: '' },
  colorBackgroundStatusSuccess: { light: '', dark: '' },
  colorBackgroundStatusWarning: { light: '', dark: '' },
  //color/Background/ToggleChecked
  colorBackgroundToggleCheckedDisabled: { light: '', dark: '' },
  // colorBackgroundButtonNormalDefault: { light: '', dark: '' },
  // colorBackgroundButtonNormalDisabled: { light: primitives.colorPrimaryP4, dark: '' },
};
