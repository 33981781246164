import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./Features/AuthSlice";
import homeReducer from "./Features/HomeSlice";
import IndexPageReducer from "./Features/IndexPageSlice";
import flashReducer from "./Features/FlashSlice";
import UserPageSlice from "./Features/UsePageSlice";
import PromptPageReducer from "./Features/PromptPageSlice";
import ChatbotPageSlice from "./Features/ChatbotPageSlice";


export const Store = configureStore({
    reducer: {
        auth: authReducer,
        home: homeReducer,
        indexPage: IndexPageReducer,
        flashbar: flashReducer,
        userPage: UserPageSlice,
        promptPage: PromptPageReducer,
        chatbotPage: ChatbotPageSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
// Define types for the Redux store dispatch and root state
export type AppDispatch = typeof Store.dispatch
export type RootState = ReturnType<typeof Store.getState>
// Export the configured Redux store
export default Store
