import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userTable: {
        data: {},
        status: null,
        error: null,
    },
    loading: {
        status: false,
    },
    userTableData: [],
    modelActionstatus: false
};

export const UserPageSlice = createSlice({
    name: 'IndexPage',
    initialState,
    reducers: {
        setUserFolders: (state: any, action) => {
            state.indexFolders = action.payload;
        },
        setUserTableData: (state: any, action) => {
            action.payload.user_list.forEach((user: any) => {
                state.userTableData.push(user);
            });
            let uniqueObjects = state.userTableData.filter(
                (obj: any, index: any, self: any) => index === self.findIndex((o: any) => o.Username === obj.Username),
            );
            state.userTableData = uniqueObjects;
        },
        setCreateUserData: (state: any, action) => {
            const dateObject = new Date();
            const formattedDateString = dateObject.toISOString();
            const newUser: any = {
                Username: action.payload.userid,
                Attributes: [
                    {
                        Name: 'sub',
                        Value: action.payload.userid,
                    },
                    {
                        Name: 'email_verified',
                        Value: 'true',
                    },
                    {
                        Name: 'given_name',
                        Value: action.payload.first_name,
                    },
                    {
                        Name: 'family_name',
                        Value: action.payload.last_name,
                    },
                    {
                        Name: 'custom:user_role',
                        Value: action.payload.user_role,
                    },
                    {
                        Name: 'email',
                        Value: action.payload.email,
                    },
                    {
                        Name: 'custom:category_index',
                        Value: action.payload.category_index,
                    },
                ],
                UserCreateDate: formattedDateString,
                UserLastModifiedDate: formattedDateString,
                Enabled: true,
                UserStatus: 'FORCE_CHANGE_PASSWORD',
            };
            state.userTableData.push(newUser);
            state.modelActionstatus = false;
        },
        setUpdateUserTableData: (state: any, action) => {
            const existingUserIndex = state.userTableData.findIndex(
                (existingUser: any) => existingUser.Username === action.payload.userid,
            );
            if (existingUserIndex !== -1) {
                const updatedAttributes = [
                    {
                        Name: 'given_name',
                        Value: action.payload.first_name,
                    },
                    {
                        Name: 'family_name',
                        Value: action.payload.last_name,
                    },
                    {
                        Name: 'custom:user_role',
                        Value: action.payload.user_role,
                    },
                    {
                        Name: 'email',
                        Value: action.payload.email,
                    },
                    {
                        Name: 'custom:category_index',
                        Value: action.payload.category_index,
                    },
                ];
                const updateUser = {
                    ...state.userTableData[existingUserIndex],
                    Attributes: updatedAttributes,
                };
                state.userTableData[existingUserIndex] = updateUser;
            }
            state.modelActionstatus = false;
        },
        deleteUsersInTableData: (state, action) => {
            const userIDsToDelete = action.payload || [];
            userIDsToDelete.forEach((userID: any) => {
                state.userTableData = state.userTableData.filter(
                    (user: any) => user.Username !== userID,
                );
            });
            state.modelActionstatus = false;
        },
        setLoadingStatus: (state, action) => {
            state.loading.status = action.payload;
        },
        setUsertModelActionstatus: (state, action) => {
            state.modelActionstatus = action.payload;
        },
    },
});

export const {
    setUserFolders,
    setUserTableData,
    setLoadingStatus,
    setCreateUserData,
    deleteUsersInTableData,
    setUpdateUserTableData,
    setUsertModelActionstatus
} = UserPageSlice.actions;

export const userTableData = (state: any) => state.userPage.userTableData;
export const totalUser = (state: any) => state.userPage.totalUserCount;
export const loadingStatus = (state: any) => state.userPage.loading;
export const createUserData = (state: any) => state.userPage.createUserData;
export const userListNextPageToken = (state: any) => state.userPage.nextPageToken;
export const usertModelActionstatus = (state: any) => state.userPage.modelActionstatus;
export default UserPageSlice.reducer;
