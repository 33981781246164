import React from 'react';
import {
    AppLayout,
    Spinner,
    // ContentLayout,
    Flashbar,
} from '@cloudscape-design/components';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SideNavDrawer from "./components/navigation/SideNavDrawer";
import { getUserRole } from './Redux/Features/AuthSlice';
const SearchPage = React.lazy(() => import("./pages/SearchPage"));
// const SearchPage2 = React.lazy(() => import("./pages/SearchPage2"));
const FolderPage = React.lazy(() => import("./pages/FolderPage"));
const IndexPage = React.lazy(() => import("./pages/IndexPage"));
const UserPage = React.lazy(() => import('./pages/UserPage'));
const PromptPage = React.lazy(() => import('./pages/PromptPage'));
const ChatBotPage = React.lazy(() => import('./pages/ChatBotPage'));
export default function AuthRoutes() {
    // const appLayout = useRef(null);
    const { pathname } = useLocation();
    const items = useSelector((state: any) => state.flashbar.flashData);
    // Get user role using Redux state.
    const userRole = useSelector(getUserRole);
    return (
        <React.Fragment>
            <AppLayout
                // ref={appLayout}
                toolsHide={true}
                navigation={<SideNavDrawer />}
                contentHeader={
                    pathname !== "/" &&
                    pathname !== "/FolderPage" && (
                        <Flashbar data-id="top-flashbar" items={items} />
                    )
                }
                content={
                    <>
                        {/* <ContentLayout header={<></>}></ContentLayout> */}
                        <React.Suspense fallback={<Spinner size="large" />}>
                            <Routes>
                                <Route path="/" element={<SearchPage />} />
                                <Route path="/FolderPage" element={<FolderPage />} />
                                {userRole === 'Admin' && (
                                    <>
                                        <Route path="/FileManagement" element={<IndexPage />} />
                                        <Route path='/UserManagement' element={<UserPage />} />
                                        <Route path='/PromptManagement' element={<PromptPage />} />
                                        <Route path='/ChatbotManagement' element={<ChatBotPage />} />
                                    </>
                                )}
                            </Routes>
                        </React.Suspense>
                    </>
                }
            />
        </React.Fragment>
    );
}
