import { primitives } from '../colors';

export const colorText = {
  //color/Text
  colorTextAccent: { light: primitives.colorPrimaryP3, dark: '' },
  colorTextCounter: { light: '', dark: '' },
  colorTextEmpty: { light: '', dark: '' },
  colorTextLabel: { light: '', dark: '' },
  //color/Text/Body
  colorTextBodyDefault: { light: '', dark: '' },
  colorTextBodySecondary: { light: '', dark: '' },
  //color/Text/Breadcrumb
  colorTextBreadcrumbCurrent: { light: '', dark: '' },
  colorTextBreadcrumbIcon: { light: '', dark: '' },
  //color/Text/Button/Normal
  colorTextButtonNormalActive: { light: '', dark: '' },
  colorTextButtonNormalDefault: { light: '', dark: '' },
  colorTextButtonNormalHover: { light: '', dark: '' },
  //color/Text/Button/Primary
  colorTextButtonPrimaryActive: { light: '', dark: '' },
  colorTextButtonPrimaryDefault: { light: '', dark: '' },
  colorTextButtonPrimaryHover: { light: '', dark: '' },
  //color/Text/DropdownItem
  colorTextDropdownItemFilterMatch: { light: '', dark: '' },
  colorTextDropdownItemHighlighted: { light: '', dark: '' },
  //color/Text/Form
  colorTextFormDefault: { light: '', dark: '' },
  colorTextFormSecondary: { light: '', dark: '' },
  //color/Text/Group
  colorTextGroupLabel: { light: '', dark: '' },
  //color/Text/Heading
  colorTextHeadingDefault: { light: '', dark: '' },
  colorTextHeadingSecondary: { light: '', dark: '' },
  //color/Text/HomeHeader
  colorTextHomeHeaderDefault: { light: '', dark: '' },
  colorTextHomeHeaderSecondary: { light: '', dark: '' },
  //color/Text/Input
  colorTextInputDisabled: { light: '', dark: '' },
  colorTextInputPlaceholder: { light: '', dark: '' },
  //color/Text/Interactive
  colorTextInteractiveActive: { light: primitives.colorPrimaryP3, dark: '' },
  colorTextInteractiveDefault: { light: primitives.colorPrimaryP3, dark: '' },
  colorTextInteractiveDisabled: { light: primitives.colorExtendedNeutralN600, dark: '' },
  colorTextInteractiveHover: { light: '', dark: '' },
  //color/Text/Interactive/Inverted
  colorTextInteractiveInvertedDefault: { light: '', dark: '' },
  colorTextInteractiveInvertedHover: { light: '', dark: '' },
  //color/Text/LayoutToggle
  colorTextLayoutToggle: { light: '', dark: '' },
  colorTextLayoutToggleActive: { light: '', dark: '' },
  colorTextLayoutToggleHover: { light: '', dark: '' },
  colorTextLayoutToggleSelected: { light: '', dark: '' },
  //color/Text/Link
  colorTextLinkDefault: { light: primitives.colorPrimaryP3, dark: '' },
  colorTextLinkHover: { light: primitives.colorPrimaryP2, dark: '' },
  //color/Text/Notification
  colorTextNotificationDefault: { light: '', dark: '' },
  //color/Text/Status
  colorTextStatusError: { light: '', dark: '' },
  colorTextStatusInactive: { light: primitives.colorTextDisabled, dark: '' },
  colorTextStatusInfo: { light: primitives.colorTextSecondary, dark: '' },
  colorTextStatusSuccess: { light: primitives.colorSuccessMain, dark: '' },
  colorTextStatusWarning: { light: primitives.colorWarningMain, dark: '' },
  //color/Text/TopNavigation
  colorTextTopNavigationTitle: { light: '', dark: '' },
};
