import { createSlice } from '@reduxjs/toolkit';

interface PromptPageState {
    promptLoading: {
        status: boolean;
    };
    modelActionstatus: boolean;
    promptTableData: any;
    promptNextPageToken: string | null;
}

const initialState: PromptPageState = {
    promptLoading: {
        status: false,
    },
    modelActionstatus: false,
    promptTableData: '',
    promptNextPageToken: null,
};

export const PromptPageSlice = createSlice({
    name: 'PromptPage',
    initialState,
    reducers: {
        setpromptTableData: (state: any, action) => {
            state.promptTableData = action.payload;
            if (state.promptTableData.length !== 0) {
                let uniqueObjects = state.promptTableData.filter(
                    (obj: any, index: any, self: any) => index === self.findIndex((o: any) => o.id === obj.id),
                );
                state.promptTableData = uniqueObjects;
            }
        },
        setaddpromptTableData: (state: any, action) => {
            state.promptTableData.push(action.payload);
            state.modelActionstatus = false;
        },
        setEditPromptTableData: (state: any, action) => {
            state.promptTableData = state.promptTableData.filter(
                (prompt: any) => prompt.id !== action.payload.id,
            );
            state.promptTableData.push(action.payload);
            state.modelActionstatus = false;
        },
        setdeletepromptTableData: (state: any, action) => {
            const promptIDsToDelete = action.payload || [];
            promptIDsToDelete.forEach((promptID: any) => {
                state.promptTableData = state.promptTableData.filter(
                    (prompt: any) => prompt.id !== promptID,
                );
            });
            state.modelActionstatus = false;
        },
        setPromptLoading: (state, action) => {
            state.promptLoading.status = action.payload;
        },
        setPromptModelActionstatus: (state, action) => {
            state.modelActionstatus = action.payload;
        },
    },
});

export const {
    setpromptTableData,
    setPromptLoading,
    setaddpromptTableData,
    setdeletepromptTableData,
    setEditPromptTableData,
    setPromptModelActionstatus
} = PromptPageSlice.actions;

export const promptLoading = (state: any) => state.promptPage.promptLoading;
export const getpromptTableData = (state: any) => state.promptPage.promptTableData;
export const promptModelActionstatus = (state: any) => state.promptPage.modelActionstatus;
export default PromptPageSlice.reducer;
