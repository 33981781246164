import { createSlice } from "@reduxjs/toolkit";

import { } from "../Thunk/HomeThunk";

export interface HomeState {
  sessionid: string;
  chatHistoryOld: any;
  resetChat: boolean;
  userSessions: string;
  connectionId: string;
  startNewConversation: boolean;
}

const initialState: HomeState = {
  sessionid: "",
  chatHistoryOld: [],
  resetChat: false,
  userSessions: "",
  connectionId: '',
  startNewConversation: true
};

export const HomeSlice = createSlice({
  name: "Home",
  initialState,
  reducers: {
    setSessionId: (state, action) => {
      state.sessionid = action.payload;
    },
    setConnectionId: (state, action) => {
      state.connectionId = action.payload;
    },
    setChatHistoryData: (state, action) => {
      state.chatHistoryOld = action.payload;
    },
    setResetChat: (state, action) => {
      state.resetChat = action.payload;
    },
    setUserSessions: (state, action) => {
      state.userSessions = action.payload;
    },
    setStartNewConversation: (state, action) => {
      state.startNewConversation = action.payload;
    },
  },
});

export const {
  setSessionId,
  setConnectionId,
  setChatHistoryData,
  setResetChat,
  setUserSessions,
  setStartNewConversation
} = HomeSlice.actions;

export default HomeSlice.reducer;
