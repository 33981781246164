import React from 'react'

const dotBadge = ({ color }: any) => {
    const dotStyle = {
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: color || 'black',
        display: 'inline-block',
        marginRight: '4px',
    };
    return (
        <span style={dotStyle}></span>
    )
}

export default dotBadge