import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    flashData: []
};

export const FlashSlice = createSlice({
    name: 'FlashSlice',
    initialState,
    reducers: {
        setFlashBarData: (state, action) => {
            state.flashData = action.payload
        }
    }
})

export const { setFlashBarData } = FlashSlice.actions
export default FlashSlice.reducer