import React, { ReactNode, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import {
  setAuthStatus,
  setUsername,
  setUserRole,
} from "../../Redux/Features/AuthSlice";
import AuthFlow from "./AuthFlow";
interface AppAuthProps {
  children: ReactNode;
}
export default function AppAuth({ children }: Readonly<AppAuthProps>) {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const dispatch = useDispatch();
  useEffect(() => {
    // Dispatch for setAuthStatus
    dispatch(setAuthStatus(authStatus));
  }, [authStatus]);

  // Get Current User Info
  async function getUsername() {
    const username = await Auth.currentUserInfo();
    // dispatch UserName
    dispatch(setUsername(username));
    // Set user role to Redux state.
    dispatch(setUserRole(username?.attributes?.['custom:user_role']))
  }
  if (authStatus === "configuring") {
    getUsername();
  }
  if (authStatus === "authenticated") {
    getUsername();
    return <>{children}</>;
  } else if (authStatus === "configuring") {
    return <></>;
  } else {
    return <AuthFlow />;
  }
}
